<!-- service-edit -->
<template>
  <el-dialog
    class="service-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="service-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="70px"
      @submit.native.prevent
    >
      <el-form-item label="QQ号:" prop="qq">
        <el-input v-model.trim="model.qq" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="微信号:" prop="wechat">
        <el-input v-model.trim="model.wechat" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="公众号:" prop="wechatOfficialAccount">
        <el-input
          v-model.trim="model.wechatOfficialAccount"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item class="from-bg" label="背景图:" prop="bg">
        <div class="form-bg">
          <choose-file
            :extensions="bgFileExtensions"
            :size="bgFileSize"
            @on-mime-invalid="handleBgFileTypeInvalid"
            @on-size-exceeded="handleBgFileSizeExceeded"
            @on-file-choose="handleBgFileChoose"
          >
            <div class="bg-img">
              <div v-if="!model.bg" class="img-choose" />

              <template v-else>
                <img class="img-thumbnail" :src="model.bg" />

                <img class="img-preview" :src="model.bg" />
              </template>
            </div>
          </choose-file>

          <div class="bg-error">{{ bgFileErrorMsg }}</div>
        </div>
      </el-form-item>

      <el-form-item label="状态:" prop="status">
        <el-radio-group v-model="model.status">
          <el-radio
            v-for="item in serviceStatusList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>

    <bg-cropper
      v-model="showCropperDialog"
      :image="bgFile"
      :width="300"
      :ratio="3 / 1"
      @on-close="handleCropperClose"
      @on-ok="handleCropperOkClick"
    />

    <template v-slot:footer>
      <div class="service-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
        >
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import ChooseFile from '../../../../components/choose-file';
  import serviceStatus from '../../../../enum/service-status';
  import { isPositiveIntString } from '../../../../util/validator.js';
  import {
    getService,
    addService,
    updateService,
  } from '../../../../api/operation/service';

  // QQ 号校验
  const qq = (rule, value, callback) => {
    isPositiveIntString(value) && value.length >= 5
      ? callback()
      : callback(new Error('请填写有效的 QQ 号'));
  };

  export default {
    name: 'service-edit',
    components: {
      ChooseFile,
      BgCropper: () =>
        import(
          /* webpackChunkName: 'service-edit-cropper' */ '../../../../components/image-cropper'
        ),
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的客服对象
        service: null,
        model: null,
        rules: {
          qq: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: qq, trigger: 'change' },
          ],
          wechat: [{ required: true, message: '请填写', trigger: 'change' }],
          wechatOfficialAccount: [
            { required: true, message: '请填写', trigger: 'change' },
          ],
          bg: [{ required: true, message: '请填写', trigger: 'change' }],
          status: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        // 背景图图片文件
        bgFile: '',
        bgFileExtensions: ['png', 'jpg', 'jpeg'],
        bgFileSize: 50 * 1024 * 1024,
        // 背景图图片错误文案
        bgFileErrorMsg: '',
        // 是否显示裁切图片对话框
        showCropperDialog: false,
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('operation/service', ['serviceId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.serviceId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}客服`;
      },
      // 客服状态列表
      serviceStatusList() {
        const { map } = serviceStatus;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      ...mapActions(['actUserInfo']),

      // 初始化模型
      initModel(data = {}) {
        const {
          qq = '',
          wechat = '',
          wechatOfficialAccount = '',
          bg = '',
          status = serviceStatus.enum.disabled,
        } = data || {};

        this.model = {
          qq,
          wechat,
          wechatOfficialAccount,
          bg,
          status,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.serviceId,
        };
        const method = this.isEdit ? updateService : addService;
        const res = await method(params);

        return !!res;
      },
      // 获取客服对象详情
      async getService() {
        const res = await getService(this.serviceId);

        if (!res) return false;

        this.service = res.data;

        return true;
      },
      // 背景图文件 mime 无效
      handleBgFileTypeInvalid() {
        this.bgFileErrorMsg = `请选择 ${this.bgFileExtensions.join(
          '、'
        )} 类型的图片`;
      },
      // 背景图文件大小超出
      handleBgFileSizeExceeded() {
        this.bgFileErrorMsg = `请选择大小不超过 ${
          this.bgFileSize / 1024 / 1024
        }MB 的图片`;
      },
      // 背景图文件选取完成
      handleBgFileChoose(files) {
        this.bgFileErrorMsg = '';

        const bgFile = files[0];

        if (!bgFile) return;

        this.bgFile = bgFile;
        this.showCropperDialog = true;
      },
      // 图片裁切对话框取消按钮单击
      handleCropperClose() {
        this.bgFile = '';
        this.showCropperDialog = false;
      },
      // 图片裁切对话框确定按钮单击
      handleCropperOkClick({ base64 }) {
        this.model.bg = base64;
        this.showCropperDialog = false;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getService();

        this.loading = false;

        if (!success) return;

        this.initModel(this.service);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.service = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .service-edit {
    .service-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }

      .form-bg {
        display: flex;
        position: relative;

        .bg-img {
          position: relative;
          width: 120px;
          height: 40px;
          cursor: pointer;

          .img-choose {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: dashed 1px #409eff;
            border-radius: 3px;

            &::before {
              content: '+';
              margin-top: -5px;
              font-size: 26px;
              font-weight: 400;
              color: #409eff;
            }
          }

          .img-thumbnail {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }

          .img-preview {
            display: none;
            position: absolute;
            top: 0;
            left: 105%;
            z-index: 1;
            width: 330px;
            height: 110px;
            border-radius: 5px;
          }

          &:hover .img-preview {
            display: block;
          }
        }

        .bg-error {
          position: absolute;
          bottom: -24px;
          left: 0;
          font-size: 12px;
          color: #f56c6c;
        }
      }
    }
  }
</style>
